@import "~@odw/lnc-styles/lib/scss/abstracts/breakpoints";
@import "~@odw/lnc-styles/lib/scss/abstracts/colors";
@import "./keyframes.scss";

// ====================
//    PARCOURS GUIDE
// ====================

#etape-1 {
  width: 100%;

  h1 {
    padding: 14.5px;

    font-size: 42px;
    font-weight: bold;

    text-align: center;
  }

  @include device(desktop) {
    margin-top: 200px;
  }

  @include device(tablet) {
    margin-top: 210px;
  }
}

#etape-3 {
  align-items: flex-start;
  gap: 15px;
  padding: 10px;

  ul {
    margin-left: 35px;
    margin-bottom: 20px;
  }

  #button-to-step4 {
    margin-left: 35px;
  }

  @include device(desktop) {
    margin-top: 125px;
  }

  @include device(tablet) {
    margin-top: 280px;
  }
}

#etape-5 {
  gap: 15px;
  width: 100%;

  ul {
    margin-left: 35px;
  }

  div {
    justify-content: end;
  }

  @include device(desktop) {
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 260px;

    ul {
      flex: 0 0 45%;
    }

    div {
      flex: 0 0 100%;
    }
  }

  @include device(tablet) {
    flex-direction: column;
    margin-top: 280px;
  }
}

#etape-7 {
  gap: 15px;
  width: 100%;

  ul {
    margin-left: 35px;
  }

  div {
    justify-content: end;
  }

  @include device(desktop) {
    margin-top: 215px;
  }

  @include device(tablet) {
    margin-top: 290px;
  }
}

#etape-9 {
  gap: 15px;
  padding: 15px;
  width: 100%;

  ul {
    margin-left: 35px;
    padding: 10px;
  }

  @include device(desktop) {
    margin-top: 125px;
  }

  @include device(tablet) {
    margin-top: 270px;
  }
}

#etape-11 {
  @include device(desktop) {
    margin-top: 125px;
  }

  @include device(tablet) {
    margin-top: 270px;
  }
}

#etape-12 {
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 150px;

  img,
  h3 {
    align-items: center;
    flex: 0 0 50%;
    position: relative;

    animation: move-down 5s infinite;
    animation-delay: 1s;
  }

  div {
    flex: 0 0 100%;
  }

  #jai-compris-12 {
    margin-top: 250px;
  }
}

#etape-13 {
  gap: 14.49px;
  padding: 14.49px;

  @include device(desktop) {
    margin-top: 165px;

    div:nth-child(1),
    div:nth-child(3) {
      justify-content: flex-end;
    }
  }

  @include device(tablet) {
    margin-top: 150px;

    div:nth-child(1),
    div:nth-child(3) {
      justify-content: center;
    }
  }
}

#etape-14 {
  width: 100%;

  h3 {
    text-align: center;
  }
}

#etape-commune {
  gap: 15px;
  width: 100%;

  font-size: 32px;

  #arrow {
    margin-top: 20px;
    padding-left: 15px;
    padding-top: 15px;

    animation: rotate 3s infinite;
  }

  #text-arrow {
    padding: 15px;
    white-space: nowrap;
  }
}

#mobile-step {
  margin-top: 40px;

  border-radius: 4px;

  overflow: hidden;

  div:nth-child(1) {
    background-color: $bleu-enedis-500;

    img {
      width: 100%;
    }
  }

  div:nth-child(2) {
    align-items: center;
    align-self: stretch;
    gap: 12px;
    padding: 32px;

    background-color: $neutre-50;

    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    text-align: center;

    p:nth-child(1) {
      font-size: 20px;
      font-weight: 700;
    }

    p:nth-child(2) {
      font-size: 14px;
    }
  }
}

#close-button {
  float: right;
  position: fixed;

  pointer-events: auto;

  @include device(tablet) {
    z-index: 9999;
  }
}

#close {
  margin-top: 200px;

  #close-text {
    text-align: center;
  }
}

#button-exit {
  button {
    gap: 8px;
    height: 54px;
    padding: 14px 16px 15px;

    color: $blanc;
    font-size: 16px;

    fill: white;

    border: ridge;
    border-radius: 5px;
  }
}

#backdrop {
  pointer-events: none;
  z-index: 999;
}

#backdrop::after {
  content: "";
  position: absolute;
  top: var(--before-top, 0);
  left: var(--before-left, 0);
  bottom: var(--before-bottom, 0);
  right: var(--before-right, 0);
  width: var(--before-width, 0);
  height: var(--before-height, 0);

  box-shadow: 0 0 2px 100vmax rgba(0, 0, 0, 0.8);
}

.backdrop-between {
  box-shadow: 0 60px rgba(0, 0, 0, 0.8);

  transition: box-shadow 0.5s;
  transition-delay: 1s;
}

.backdrop-menu-radius::after {
  border-radius: 0 0 8px 8px;
}

.backdrop-tutoriels-radius::after {
  border-radius: 5px;
}

.backdrop-encarts-radius::after {
  border-radius: 4px;
}

.negative-height {
  margin-top: -60px;
}

.shadow-effect::after {
  transition: all 1s;
}

.shadow-effect-slow::after {
  transition: all 0s;
}

#encarts-accueil {
  height: 100%;
}

.fade-in {
  animation: animation-fade-in 1s;
}

.reversed-arrow {
  transform: scaleX(-1);
  animation: reversed-rotate 3s infinite !important;
}

.zoom-encart-tutoriels {
  animation: zoom-fixe 1s linear forwards;
}

.font-size-tutoriels {
  animation: increase-font-size 1s linear forwards;
}

.zoom-icon {
  animation: increase-icon-size 1s linear forwards;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.hidden-step {
  display: none;
}

.no-scroll {
  overflow: hidden;
}

.dismiss-pointer {
  pointer-events: none;
}

.allow-pointer {
  pointer-events: auto;
}

.filter-blur {
  filter: blur(2px);
}

.parcours-didactique {
  position: relative;
  pointer-events: auto;

  z-index: 9999;
}

ul.parcours-list {
  font-size: 32px;

  list-style-type: square;

  li::marker {
    font-size: initial;
  }
}

.lnc-custom-rows {
  grid-template-rows: repeat(2, auto);
}

.lnc-first-row {
  grid-row: 1;
}

.lnc-second-row {
  grid-row: 2;
}
