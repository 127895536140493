@import "~@odw/lnc-styles/lib/scss/abstracts/breakpoints";
@import "~@odw/lnc-styles/lib/scss/abstracts/colors";

// ====================
//       ACCUEIL
// ====================

#overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  z-index: 99;
}

.cdk-overlay-container{
  z-index: 10001 !important;
}

lnc-web-multi-select{
  #lnc-multi-select-1{
    max-width: 390px;
  }
}

lnc-web-footer {
  margin-top: 0px;
}

.page-accueil {
  width: 100%;
  background: $bleu-enedis-200;
}

.img-background {
  @include device(mobile) {
    background-image: url("../../img/accueil/fil_page_accueil.png");
    background-repeat: no-repeat;
    background-position: top 400px right;
  }

  @include device(desktop) {
    background-image: url("../../img/accueil/fil_et_terre_desktop.png");
    background-repeat: no-repeat;
    background-position: top 82px right -40px;
  }
}

.encart-tutoriels {
  width: 100%;
  max-height: 68px;
  padding: 10px;

  background-color: $turquoise-500;
  color: $blanc;
  border-radius: 5px;

  @include device(tablet) {
    width: auto;
  }

  @include device(desktop) {
    width: auto;
  }

  p {
    font-size: 14px;

    @include device(tablet) {
      font-size: 13px;
    }
  }

  a {
    border-radius: 5px;
    gap: 8px;
    padding: 14px 16px;
    background: none;
    color: $blanc;
    border: white;
    font-size: 16px;
    text-decoration: none;
  }

  a:hover {
    background-color: mediumturquoise;
  }
}

.encart-accueil {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 16px 16px;

  max-width: inherit;
  background-color: $blanc;
  color: $noir;
  border-radius: 4px;
  box-shadow: 0 3px 6px 2px rgba(16, 16, 20, 0.16);

  @include device(mobile) {
    height: 165px;
  }

  @include device(tablet) {
    justify-content: space-between;
    flex-wrap: nowrap;

    .img-linky {
      height: 160px;
      width: 160px;
      position: relative;
      right: 25px;
    }

    .img-home {
      height: 141px;
      width: 139px;
    }
  }

  @include device(desktop) {
    max-width: inherit;
    justify-content: space-between;
    flex-wrap: nowrap;

    .img-linky {
      height: 160px;
      width: 160px;
      position: relative;
      right: 25px;
    }

    .img-home {
      height: 141px;
      width: 139px;
    }
  }

  h5 {
    font-size: 20px;
    position: relative;
    right: 25px;
  }

  p {
    font-size: 17px;
    display: flex;
    flex-wrap: wrap;
  }

  .encart-text {
    padding: 10px;
  }

  .encart-acceder-vos-compteurs-button {
    width: 100%;
    position: relative;
    left: 35px;
    bottom: 25px;

    @include device(tablet) {
      width: auto;
      bottom: 0;
    }

    @include device(desktop) {
      width: auto;
      left: 40px;
      bottom: 0;
    }
  }

  .encart-acceder-visualiser-mesures-button {
    width: 100%;
    position: relative;
    left: 35px;

    @include device(tablet) {
      width: auto;
    }

    @include device(desktop) {
      width: auto;
    }

    h5 {
      position: relative;
      right: 25px;
    }

    p {
      font-size: 17px;
      display: flex;
      flex-wrap: wrap;
    }

    .encart-text {
      padding: 10px;
    }

    .encart-acceder-vos-compteurs-button {
      width: 100%;
      position: relative;
      left: 35px;
      bottom: 25px;

      @include device(tablet) {
        width: auto;
        bottom: 0;
      }

      @include device(desktop) {
        width: auto;
        left: 40px;
        bottom: 0;
      }
    }

    .encart-acceder-visualiser-mesures-button {
      position: relative;
      left: 35px;

      @include device(tablet) {
        width: auto;
      }

      @include device(desktop) {
        width: auto;
        left: 40px;
      }
    }
  }
}

.encart-parcours-guide {
  max-width: inherit;
  height: 316px;

  background-color: $blanc;
  color: $noir;
  border-radius: 4px;
  box-shadow: 0 3px 6px 2px rgba(16, 16, 20, 0.16);

  @include device(tablet) {
    height: 212px;
  }

  p {
    font-size: 17px;
  }
}

.width-encart-col {
  width: 100%;

  @include device(desktop) {
    width: 50%;
  }
}

.img-linky {
  height: 125px;
  width: 125px;
  position: relative;
  right: 25px;
}

.img-home {
  height: 101px;
  width: 100px;
  position: relative;
  right: 10px;
}

// Style pour la popin ModalPremiereConnexion
#modal-dialog-export {
  max-width: 617px;
  margin: auto;
  z-index: 100;
}

.modal-container {
  background: $blanc;
  border-radius: 10px;

  @include device(mobile) {
    border-radius: 4px;
  }

  .modal-text-and-button {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    text-align: center;

    @include device(mobile) {
      align-items: center;
      row-gap: 12px;
    }
  }

  .modal-title {
    font-weight: bold;
  }

  .desktop-image {
    max-width: 617px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .mobile-image {
    width: 100%;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
}

.footer-img {
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: url("../../img/accueil/banniere.jpg");

  @include device(desktop) {
    background-position: center;
  }
  @include device(tablet, desktop) {
    height: 305px;
  }
  @include device(mobile) {
    width: 100%;
  }
}

.encart-wrapper {
  display: flex;
  position: relative;

  @include device(desktop) {
    right: 18.5%;
    margin: 24px auto 8px auto;

    .img-solution {
      position: relative;
      height: 158px;
      width: 158px;
      left: 74px;
      top: 10px;
    }
  }

  @include device(tablet) {
    margin: 24px 0;
  }

  @include device(mobile) {
    width: 251px;
    margin: 20px auto 8px auto;
  }

  @include device(desktop, tablet) {
    width: 384px;
  }

  .encart-aide-et-contact {
    height: 106px;
    width: 251px;
    border-radius: 90px;
    padding: 32px 54px 32px 54px;

    color: $bleu-enedis-500;
    background: $blanc;

    .text-enedis {
      min-width: 144px;
      height: 42px;
    }
  }
}

.encart-meteo-ecowatt {
  text-align: center;
  align-items: center;
  margin: 20px 0px 19px 0px;
  max-width: inherit;
  background-color: $blanc;
  color: $noir;
  border-radius: 4px;
  box-shadow: 0 3px 6px 2px rgba(16, 16, 20, 0.16);
  padding: 16px;

  div[hidden]{
    display: none !important;
  }

  #lnc-web-cards-meteo-hidden-error {
    @include device(tablet, desktop) {
      p {
        text-align: left;
      }
    }

    @media (max-width: 940px) {
      p {
        text-align: center;
      }
    }
  }

  .error_message {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 24px;
  }

  .eccor_icone {
    fill: #1423dc;
  }

  .btn_download {
    margin-top: 8px;
  }
  .download_btn_icone {
    padding-left: 5px;
    fill: #1423dc;
  }

  .ecowat-image {
    width: 95px;
    height: 28px;
    border-radius: 4px;
    padding: 4px;
    background-color: #28264c;
    margin-left: 5px;
  }

  .btn-redirect-monecowatt {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration: none;
    color: #1423dc;
    fill: #1423dc;
    gap: 8px;
    p {
      margin-right: 5px;
    }
  }
  #ecowatt {
    margin-top: 8px;

    .ecowatt-part {
      text-align: left;
      @include device(mobile, tablet){
        align-items: center;
      }
      p {
        @include device(mobile, tablet){
          font-size: 12px;
        }
        @include device(desktop){
          font-size: 14px;
        }
      }
      @include device(mobile, tablet){
        .ecowat-image{
          width: 58px;
          height: 19px;
        }
      }
    }

    #ecowatt-part-link {
      text-align: right;
      padding: 14px 16px 15px 16px;
      @include device(mobile){
        margin-top: 8px;
      }
    }

    @include device(mobile,tablet) {
      p {
        white-space: nowrap;
      }
    }
  }

  @include device(desktop) {
    #ecowatt.ecowatt-with-buttons{
      margin-top: 0px;
      #ecowatt-part-link {
        margin-top: -20px;
      }
    }
  }

  @include device(mobile) {
    .row-modif {
      flex-direction: column;
      align-items: center;
    }
  }

  .row-modif {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x) / -2);
    margin-left: calc(var(--bs-gutter-x) / -2);
    justify-content: space-between;
  }

  .row-modif > * {
    flex-shrink: 0;
    padding-right: calc(var(--bs-gutter-x) / 2);
    padding-left: calc(var(--bs-gutter-x) / 2);
    margin-top: var(--bs-gutter-y);
  }

  #modal-ecowatt-rouge-orange-feature-flipping-off, #modal-ecowatt-vert{
    lnc-web-button {
      margin-top: -32px;
    }
  }

  #modal-ecowatt-rouge-orange-feature-flipping-on{
    .lnc-tooltip-top {
      font-size: 14px;
    }

    lnc-web-toast {
      .lnc-overlay-wrapper-banner{
        margin-top: 15px;
        margin-bottom: 30px;
        position: relative;
        max-width: none !important;
        padding-right: 0px !important;
        padding-left: 0px !important;
        right: 0px;
        lnc-banner{
          box-shadow: none;
        }
      }
    }
    .meteo-card {
      width: 240px;
      @media only screen and (max-width: 768px) {
        width: inherit;
      }
      @media only screen and (max-width: 940px) {
        flex-direction: row;
        padding: 8px;
      }

      .meteo-icon-container {
        @media only screen and (max-width: 940px) {
          width: 40px;
          height: 44px;
        }

        .meteo-icon {
          @media only screen and (max-width: 940px) {
            border-radius: 10px;
          }
          svg {
            @media only screen and (max-width: 940px) {
              width: unset !important;
              height: unset !important;
            }
          }
        }
      }

      .meteo-description{
        .meteo-date-mobile-size {
          color: $neutre-50;
          font-size: 12px;
        }
        .meteo-message,
        .meteo-date {
          @media only screen and (max-width: 940px) {
            display: block;
          }
        }

        .meteo-date-mobile-size {
          @media only screen and (max-width: 940px) {
            display: none;
          }
        }

      }
    }

    .info-banner {
      align-self: end;
      @media only screen and (max-width: 768px) {
        margin-top: 28px;
        padding-left: 0px;
      }
      .banner-container{
        @media only screen and (max-width: 768px) {
          height: fit-content;
        }
      }
    }
    .info-jour {
      padding-top: 8px;
      padding-bottom: 24px;
      display: flex;
      flex-wrap: initial;
      gap: 20px;
      @media only screen and (max-width: 768px) {
        display: block;
      }
    }

    #tuile{
      display: flex;
      align-self: end;
      flex-direction: column;
      padding-top: 2px;
      gap: 6px;
    }
  }

  #modal-confirm-show-tasks{
    text-align: left;
  }

  .modal_ecowatt_vert,
  .modal_ecowatt_rouge-orange {
    text-align: left;
    .img_solution_modal_ecowatt_vert,
    .img_solution_modal_ecowatt_rouge-orange {
      text-align: center;
    }
    img {
      display: inline;
    }
    @include device(desktop) {
      lnc-web-button {
        width: 127px;
      }
      img {
        width: 199.49px;
      }
    }
    @include device(tablet) {
      lnc-web-button {
        width: 127px;
      }
      img {
        width: 199.49px;
      }
    }
    @include device(mobile) {
      img {
        width: 200px;
      }
    }
  }
}
