@keyframes animation-fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes rotate {
  10% {
    transform: rotate(0);
  }

  40% {
    transform: rotate(20deg);
  }

  60% {
    transform: rotate(20deg);
  }

  90% {
    transform: rotate(0);
  }
}

@keyframes reversed-rotate {
  10% {
    transform: scaleX(-1) rotate(0deg);
  }

  40% {
    transform: scaleX(-1) rotate(20deg);
  }

  60% {
    transform: scaleX(-1) rotate(20deg);
  }

  90% {
    transform: scaleX(-1) rotate(0deg);
  }
}

@keyframes zoom-fixe {
  0% {
    max-height: none;
    width: 421px;
    height: 68px;
  }

  50% {
    max-height: none;
    width: 518.19px;
    height: 84px;
  }

  100% {
    max-height: none;
    width: 615px;
    height: 100px;
  }
}

@keyframes increase-font-size {
  0% {
    font-size: 14px;
  }

  50% {
    font-size: 17.5px;
  }

  100% {
    font-size: 21px;
  }
}

@keyframes increase-icon-size {
  0% {
    scale: 1;
  }

  50% {
    scale: 1.25;
  }

  100% {
    scale: 1.5;
  }
}

@keyframes move-down {
  0%,
  10% {
    top: 0;
  }

  40%,
  60% {
    top: 240px;
  }

  90%,
  100% {
    top: 0;
  }
}
